/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, Title, Image, Button, Fullmap, FullmapWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-ls7uq1 css-cn85we --style2 --full" name={"uvod"} border={""} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/18947/f8d202ba6ee94b42b167a47b15fcbc09_s=3000x_.jpg);
    }
  
background-position: 51% 72%;
    `}>
          
          <ColumnWrap className="--center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--22" content={"<span style=\"color: rgb(255, 255, 255); font-weight: bold;\">Provozovna na Václavském náměstí je od 1. 4. 2024 uzavřena,<br>hledáme pro vás nové prostory.<br><br>Děkujeme za pochopení</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"o-nas"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Náš příběh"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" content={"<span style=\"color: rgb(0, 0, 0);\">Oliver's Coffee Cup je útulná kavárna s výběrovou kávou, domácími dezerty a vždy milou obsluhou. První pobočku jsme otevřeli na Václavském náměstí v Domě Módy, naši druhou pobočku najdete v samém srdci Karlína, ve Futurama Business Park.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"galerie"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--10 pt--10" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/b7e33529499644adaf4b98de1ac07030_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":535}} srcSet={"https://cdn.swbpg.com/t/18947/b7e33529499644adaf4b98de1ac07030_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/b7e33529499644adaf4b98de1ac07030_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/b7e33529499644adaf4b98de1ac07030_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/b7e33529499644adaf4b98de1ac07030_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/4db7e336e1244c8c81a1463fc3011456_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/4db7e336e1244c8c81a1463fc3011456_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/4db7e336e1244c8c81a1463fc3011456_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/4db7e336e1244c8c81a1463fc3011456_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/4db7e336e1244c8c81a1463fc3011456_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/8c160b80b7474e93a6d65a52e4053b7f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/8c160b80b7474e93a6d65a52e4053b7f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/8c160b80b7474e93a6d65a52e4053b7f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/8c160b80b7474e93a6d65a52e4053b7f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/8c160b80b7474e93a6d65a52e4053b7f_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column name={"pjwd1pdenl7"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/0c95630bf21b48c5b5f8977212c84f7a_s=660x_.png"} url={"https://bistronarodnimuzeum.cz/"} use={"url"} href={"https://bistronarodnimuzeum.cz/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/18947/0c95630bf21b48c5b5f8977212c84f7a_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/0c95630bf21b48c5b5f8977212c84f7a_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/0c95630bf21b48c5b5f8977212c84f7a_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center pt--30">
              
              <Image className="pt--06" src={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png"} url={"https://oliverscoffeecup.cz/"} use={"url"} href={"https://oliverscoffeecup.cz/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":235}} srcSet={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png 660w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/1fa2836e19d74ad6b499041d89e1e57f_s=660x_.png"} url={"https://olivacatering.cz/"} use={"url"} href={"https://olivacatering.cz/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/18947/1fa2836e19d74ad6b499041d89e1e57f_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/1fa2836e19d74ad6b499041d89e1e57f_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/1fa2836e19d74ad6b499041d89e1e57f_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border2 pb--60 pt--60" name={"lokace-rezervace"} style={{"backgroundColor":"rgba(112,156,159,1)"}} border={"2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Kde nás najdete</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box fs--48" style={{"maxWidth":484}} content={"<span style=\"color: rgb(255, 255, 255);\">Václavské náměstí</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(255, 255, 255);\">Dům módy, 1. patro<br>Václavské nám. 804, Praha<br><br>PO-PÁ&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8:30 - 20:30<br>SO-NE&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9:30 - 20:00<br></span>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/18947/dd487bb20a0242b2ae09f17ce03b83e3_s=860x_.png"} url={"https://g.page/oliverscoffeecup-cz?share"} href={"https://g.page/oliverscoffeecup-cz?share"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":670}} srcSet={"https://cdn.swbpg.com/t/18947/dd487bb20a0242b2ae09f17ce03b83e3_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/dd487bb20a0242b2ae09f17ce03b83e3_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/dd487bb20a0242b2ae09f17ce03b83e3_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/dd487bb20a0242b2ae09f17ce03b83e3_s=1400x_.png 1400w"}>
              </Image>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape2 btn-box--cColor2 btn-box--filling2" use={"file"} href={"https://cdn.swbpg.com/d/18947/20220906v2-oliverscoffeecup-menu.pdf"} target={"_blank"} content={"Menu"} document={"ad8d0f78d71a4b47ace2285a636aaa65"}>
              </Button>

              <Button className="btn-box btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape2 btn-box--cColor2 btn-box--filling2" url={"https://reservation.dish.co/widget/hydra-925a07c0-58c8-11ec-86ec-7bf2f1636ee5"} href={"https://reservation.dish.co/widget/hydra-925a07c0-58c8-11ec-86ec-7bf2f1636ee5"} content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box fs--48" content={"<span style=\"color: rgb(255, 255, 255);\">Karlín</span>"}>
              </Title>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(255, 255, 255);\">Futurama Business Park<br>&nbsp;Sokolovská 663/136F, Praha<br><br>PO-ČT &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8:00 - 16:30<br>PÁTEK &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8:00 - 15:30<br><br></span>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/18947/693c412ccbb847a8a345521ce7a76e62_s=860x_.png"} url={"https://goo.gl/maps/6215N4s1fGkLRk7s9"} href={"https://goo.gl/maps/6215N4s1fGkLRk7s9"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":644}} srcSet={"https://cdn.swbpg.com/t/18947/693c412ccbb847a8a345521ce7a76e62_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/693c412ccbb847a8a345521ce7a76e62_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/693c412ccbb847a8a345521ce7a76e62_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/693c412ccbb847a8a345521ce7a76e62_s=1400x_.png 1400w"}>
              </Image>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape2 btn-box--cColor2 btn-box--filling2" use={"page"} content={"Menu"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"9v8tyfio53"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60">
          
          <ColumnWrap className="column__flex el--4 pl--0 pr--0 flex--top" columns={"4"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Image className="--justify pl--30" alt={""} src={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":144}} srcSet={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">E-MAIL</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">info@oliverscoffeecup.cz</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">TELEFON</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">+420 234 101 138</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">SLEDUJTE NÁS</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\"><a href=\"https://www.facebook.com/oliverscoffeecup.cz\" target=\"_blank\">Facebook<br></a><a href=\"https://www.instagram.com/oliverscoffeecup/\" target=\"_blank\">Instagram</a><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}